import {filterItemsToString} from 'o365.modules.filterUtils.ts';
import context from 'o365.modules.Context.ts';

class DistinctHandler{
    _viewName = null;
    _prevWhereClause = null;
    _sortOnCount = true;
    _defaultSort = null;
    column = null;
    targetColumn = null;
    existsBinding = null;
    countField = null;
    distinctColumn = null;
    distinctTargetColumn = null;
    whereClause = null;
    _dataLoadedDate = null;

    

    sortOnCountDirection = "desc";
    sortAlphabeticallyDirection = "asc";
    get viewName(){
        return this._viewName;
    }

    set viewName(pViewName){
        this._viewName = pViewName;
    }

   

    get column(){
    

        return this._column;
    }

    get existsClause(){
        if(this.whereClause){
            return `${this.viewName},${this.existsBinding.replace("T1.","T1_.").replace("T2.","T1.").replace("T1_.","T2.")},${this.whereClause}`;
        }
        return `${this.viewName},${this.existsBinding.replace("T1.","T1_.").replace("T2.","T1.").replace("T1_.","T2.")}`;
    }

     get existsObject(){
        return {
            binding:this.existsBinding.replace("T1.","T1_.").replace("T2.","T1.").replace("T1_.","T2."),
            viewName:this.viewName,
            distinctColumn:this.distinctColumn,
            distinctTargetColumn:this.distinctTargetColumn??this.distinctColumn,
            whereClause:this.whereClause
        }
        
       
    }
  
    set sortOnCount(sortOnCount){
        this._sortOnCount = sortOnCount;
  
    }

    get sortDirection(){
        if(this.sortOnCount){
            return this.sortOnCountDirection;
        }

        return this.sortAlphabeticallyDirection;
    }


    get sortOnCount(){
        return  this._sortOnCount;
    }
    constructor(options){
        this.data = [];
        this.dataLoaded = false;
        this.dataObject = null;

        this.filterString = null;

        this.column = options.column;
        this.targetColumn = options.targetColumn;
        
        if(options.hasOwnProperty("viewName")){
            this._viewName = options["viewName"];
        }

        this.search = null;
    }
    //return will indicate if there were some changes
    setSortOnCount(sortOnCount){
     

        if(this.sortOnCount !== sortOnCount){
            //sort direction not changing only which one with previuos sorting
           this.sortOnCount = sortOnCount;
           return true;
        }else{
            if(sortOnCount){
                this.sortOnCountDirection = this.sortOnCountDirection==='asc'?'desc':'asc';
                return true;
            }else{
                this.sortAlphabeticallyDirection = this.sortAlphabeticallyDirection==='asc'?'desc':'asc';
                return true;
            }
        }
    
       
    }

    getData(reload){

        const options = {
            fields:[]
        };
        const vCountField = this.getCountField();
        const vFilterObj = this.changeFilterItems(([this.distinctTargetColumn,this.targetColumn,this.column]), this.dataObject.recordSource.getItemsClone());
        const vFilterString = vFilterObj ? filterItemsToString(vFilterObj)?.replace('[CLEARED_DISTINCT_PLACEHOLDER]', '1')?.replace('CLEARED_DISTINCT_PLACEHOLDER', '1') : null;

        if(this._prevWhereClause != this.dataObject.recordSource.getWhereClause()){
            reload = true;

        }
        if(this._dataLoadedDate && this.dataObject.state.changedDate && this._dataLoadedDate < this.dataObject.state.changedDate){
             reload = true;
        }
        if(this.dataLoaded && this.filterString == vFilterString && !reload){
            return Promise.resolve(this.data);
        }

        this.filterString = vFilterString;
        
       
        options["filterString"] = vFilterString;
        options["filterObject"] = vFilterObj;

        options.expandView = this.dataObject.recordSource.expandView;
        options.definitionProc = this.dataObject.recordSource._definitionProc;
        if (options.expandView || this.dataObject.recordSource.definitionProc || this.dataObject.recordSource.contextId) {
            options.contextId = this.dataObject.recordSource.contextId === undefined ? context.id : this.dataObject.recordSource.contextId;
        }
        if (this.dataObject.recordSource.sqlStatementParameters) {
            options.sqlStatementParameters = this.dataObject.recordSource.sqlStatementParameters;
        }
        if (this.dataObject.recordSource.definitionProcParameters) {
            options.definitionProcParameters = this.dataObject.recordSource.definitionProcParameters;
        }
      

        let vCurrentWhereClause = this.dataObject.recordSource.getWhereClause();
        this._prevWhereClause = vCurrentWhereClause;
       /* if(vCurrentWhereClause && this.search){
            vCurrentWhereClause += " AND ";
        }else if(!vCurrentWhereClause){

            vCurrentWhereClause = "";
        }*/
        if(this.existsBinding){
            this.sortOnCount = false;
        }
        if(this.distinctTargetColumn){
             options["fields"].push({
                name:this.distinctTargetColumn,
                sortDirection:this.sortDirection,
                sortOrder:1,
                groupByOrder:1
            })
        }

        if(this.targetColumn){
            options["fields"].push({
                name:this.targetColumn,
                 sortDirection:this.sortOnCount?null:this.sortDirection,
                sortOrder:this.sortOnCount?null:1,
                groupByOrder:1
            })
        }
        if(!options["fields"].find(x=>x.name == this.distinctColumn??this.column))
        options["fields"].push({
                name:this.distinctColumn??this.column,
                sortDirection:this.sortOnCount?null:this.sortDirection,
                sortOrder:this.sortOnCount?null:1,
                groupByOrder:1
        })
       // if(!this.existsBinding)
        options.fields.push({
            name:vCountField,
            alias:"Count",
            aggregate:this.dataObject.recordSource.distinctRows?"COUNT_DISTINCT":"COUNT",
            sortDirection:this.sortOnCount?this.sortDirection:null,
            sortOrder:this.sortOnCount?1:null
        });

        

        if(this.search && !this.existsBinding){
            options["whereObject"] = {
                column:(this.column),
                operator:"contains",
                value:this.search,
                type:"expression"
            }
            if(vCurrentWhereClause){
                options["whereClause"] = vCurrentWhereClause + " AND ["+(this.column) +"] LIKE '%"+this.search+"%'";
            }else{
                options["whereClause"] = "["+(this.column) +"] LIKE '%"+this.search+"%'";
            }
            
        }else{
            options["whereClause"] = vCurrentWhereClause;
        }
        if(this.existsBinding){
            const vWhere = [];
            if(options.whereClause){
               vWhere.push("("+options.whereClause+")") 
               options.whereClause = null;
            }

            if(options.filterString){
                  vWhere.push("("+options.filterString+")");
                  options.filterString = null;
            }
            if(vWhere.length){
                if(this.search){
                    const searchStatement = `T1.${this.distinctColumn} LIKE '%${this.search}%'`;
                   options.whereClause = `exists_clause(${this.dataObject.viewName}, ${this.existsBinding}, ${[...vWhere, searchStatement].join(" AND ")})`; 
                }else{
                     options.whereClause = `exists_clause(${this.dataObject.viewName}, ${this.existsBinding}, ${vWhere.join(" AND ")})`;
                }
               
            }else{
                 if(this.search){
                    options.whereClause = `exists_clause(${this.dataObject.viewName}, ${this.existsBinding} AND T2.${this.distinctColumn} LIKE '%${this.search}%')`; 
                 }else{
                     options.whereClause = `exists_clause(${this.dataObject.viewName}, ${this.existsBinding})`;
                 }
               
            }
            options.filterString = "1=1"
        }
        
        options["maxRecords"] = -1;
        options["viewName"] = this._viewName;
        options["loadRecents"] = false;
       // options["distinctRows"] = this.dataObject.recordSource.distinctRows;

        if(this.dataObject.masterDetails) options["masterDetailString"] = this.dataObject.masterDetails.getFilterString();
        if(options["masterDetailString"] && this.dataObject.masterDetails){
            options["masterDetailObject"] = this.dataObject.masterDetails.getFilterObject();
        }


        if (!(options.includeFilterObjects || this.dataObject.clientSideFiltering || options.whereObject)) {
            // deleting cause its being sent to the back otherwise and fails
            // TODO: Check if back should support whereObjects and filterObjects in the first place
            delete options.filterObject;
        }

        return new Promise((resolve)=>{
            
            this.dataObject.dataHandler.distinct(options).then((pData)=>{
                this.dataLoaded = true;
                this.data = pData;
                this._dataLoadedDate = new Date();
                resolve(pData);
            })
        });
        
    }

    getCountField(){
        if(this.countField){
            return this.countField;
        }
        if(this.dataObject.fields.uniqueField){
            return this.dataObject.fields.uniqueField
        }

        return this.targetColumn?? this.column;
       
      
    }

    setDataObject(pDataObjectId){
        if(typeof pDataObjectId === 'string'){
            this.dataObject = o365.model.dataObjects[pDataObjectId];
        }else{
            this.dataObject = pDataObjectId;
        }

        if(!this._viewName){
            this._viewName = this.dataObject.viewName;
        }
        
    }

    changeFilterItems = (pKey, pObj)=>{
        if (!pObj) return null;
        
        if(pObj.length){
             for(var i = 0; i< pObj.length; i++){
                 if(pKey.indexOf(pObj[i].column)>-1){
                    
                     pObj[i].column = 'CLEARED_DISTINCT_PLACEHOLDER';
                     pObj[i].value = '1';
                     pObj[i].operator = 'equals';
                    // delete pObj[i];
                    // pObj.length -=1;
                   
                   
                   // return;
                }
                this.changeFilterItems(pKey, pObj[i]); 
             }
        }
        
        if(pObj.items ){
            this.changeFilterItems(pKey, pObj.items);
        }
    
        return pObj;
    }
}

export {DistinctHandler}; 
